<template>
    <div :class="$style.page">
      <div :class="$style.wrapper">
        <el-button
          size="small"
          type="primary"
          @click="submitForm()"
        >
          Cоздать заказ
        </el-button>
      </div>
      <el-form
        :class="$style.form"
        :model="loyalty"
        ref="form"
        :rules="rules"
        label-position="right"
        label-width="220px"
      >
        <el-form-item label="Название" prop="name">
          <el-input v-model="loyalty.name"/>
        </el-form-item>

        <el-form-item label="Размер кэшбэка %" prop="cashbackSize">
          <el-input v-model.number="loyalty.cashbackSize"/>
        </el-form-item>

        <el-form-item label="Требуемая сумма (руб)" prop="requiredSum">
          <el-input v-model.number="loyalty.requiredSum"/>
        </el-form-item>

        <el-form-item label="Срок подтверждения (мес)" prop="expiredPeriod">
          <el-input v-model.number="loyalty.expiredPeriod"/>
        </el-form-item>

        <el-form-item label="Цвет">
          <el-color-picker v-model="loyalty.color" />
        </el-form-item>

      </el-form>
    </div>
  </template>

  <script>

  import notifications from '@/mixins/notifications'
  import delivery from '@/delivery'

  export default {
    mixins: [notifications],
    data() {
      return {
        loyalty: {
          name: '',
          cashbackSize: 0,
          requiredSum: 0,
          expiredPeriod: 0,
          color: "#FFD700",
        },
        rules: {
          name: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          cashbackSize: [
            {
                required: true,
                type: 'number',
                min: 1,
                max: 100,
                message: 'Кэшбэк должен быть больше 0 и меньше 100',
                trigger: 'blur'
            }
          ],
          requiredSum: [
            {
                required: true,
                type: 'number',
                min: 1,
                message: 'Требуемая сумма должена быть больше 0',
                trigger: 'blur'
            }
          ],
          expiredPeriod: [
            {
                required: true,
                type: 'number',
                min: 1,
                message: 'Срок подтверждения должен быть больше 0',
                trigger: 'blur'
            }
          ],
        },
      }
    },
    methods: {
      async submitForm() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            const loading = this.$loading({
            lock: true,
          })

            const data = {
              name: this.loyalty.name,
              cashbackSize: Number(this.loyalty.cashbackSize),
              requiredSum: Number(this.loyalty.requiredSum),
              expiredPeriod: Number(this.loyalty.expiredPeriod),
              color: this.loyalty.color,
            }

            const response =
            await delivery.AddwineCore.LoyaltyProgramActions.create(data)

            loading.close()

            if (response.error) return

            this.showNotification('Программа лояльности успешно создана', 'success')
            this.$router.push('/addwine/loyalty-program')
          }
        })
      },
    },
  }
  </script>

  <style lang="scss" module>
  .page {
    padding: 1rem;
    .wrapper {
      @include stickyWrapper;
    }

    .form {
      & > div > label {
        text-align: left;
      }
    }
}
</style>